//USEFUL MODULES
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

//STYLES
import './GaleryCard.css';

//COMPONENTS
import { Col } from 'react-simple-flex-grid';

//STYLES
import "react-simple-flex-grid/lib/main.css";

export default class GaleryCard extends Component {

    render() {
        if(!isMobile) {
            return (
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="card-box"
                    onMouseOver={()=>this.props.onMouse(this.props.project.id)}
                    onMouseOut={()=>this.props.onMouse(null)} 
                    onClick={()=>this.props.onClick()}>
                    {
                        !this.props.active ? 
                        <p className="card-name">{this.props.project.nome}</p>
                        : null
                    }
                    {
                        this.props.active ? 
                        <div className="project-img" style={{backgroundImage: `url(${this.props.project.imagem_colorida})`}} alt=""></div>
                        : <div className="project-img" style={{backgroundImage: `url(${this.props.project.imagem_preto_e_branco})`}} alt=""></div>
                    }
                </Col> 
            )
        } else {
            return (
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="card-box"
                    onClick={()=>this.props.onClick()}>
                    {
                        !this.props.active ? 
                        <p className="card-name">{this.props.project.nome}</p>
                        : null
                    }
                    {
                        this.props.active ? 
                        <div className="project-img" style={{backgroundImage: `url(${this.props.project.imagem_colorida})`}} alt=""></div>
                        : <div className="project-img" style={{backgroundImage: `url(${this.props.project.imagem_preto_e_branco})`}} alt=""></div>
                    }                        
                </Col>
            )
        }
    }
}