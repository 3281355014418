module.exports = [
    {
        id: 'residencial',
        nome: 'RESIDENCIAL',
        imagem_colorida: 'https://sapearquitetos.com.br/categorias/residencial/vp/capa.jfif',
        imagem_preto_e_branco: 'https://sapearquitetos.com.br/categorias/residencial/vp/capa_pb.jpg',
    },
    {
        id: 'comercial',
        nome: 'COMERCIAL',
        imagem_colorida: 'https://sapearquitetos.com.br/categorias/comercial/sesvesp/capa.jpg',
        imagem_preto_e_branco: 'https://sapearquitetos.com.br/categorias/comercial/sesvesp/capa_pb.jpg',
    },
    {
        id: 'cenografia',
        nome: 'CENOGRAFIA',
        imagem_colorida: 'https://sapearquitetos.com.br/categorias/cenografia/site-lacta/capa.jpg',
        imagem_preto_e_branco: 'https://sapearquitetos.com.br/categorias/cenografia/site-lacta/capa_pb.jpg',
    },
    {
        id: 'exercicios',
        nome: 'EXERCÍCIOS',
        imagem_colorida: 'https://sapearquitetos.com.br/categorias/exercicios/chale/capa.jpg',
        imagem_preto_e_branco: 'https://sapearquitetos.com.br/categorias/exercicios/chale/capa_pb.jpg',
    },
    {
        id: 'expografia',
        nome: 'EXPOGRAFIA',
        imagem_colorida: 'https://sapearquitetos.com.br/categorias/expografia/vinicius/capa.jpg',
        imagem_preto_e_branco: 'https://sapearquitetos.com.br/categorias/expografia/vinicius/capa_pb.jpg',
    },
    {
        id: 'ilustracoes',
        nome: 'ILUSTRAÇÕES',
        imagem_colorida: 'https://sapearquitetos.com.br/categorias/ilustracoes/natura/capa.jpg',
        imagem_preto_e_branco: 'https://sapearquitetos.com.br/categorias/ilustracoes/natura/capa_pb.jpg',
    },
]