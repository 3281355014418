//USEFUL MODULES
import React, { Component } from 'react';

//STYLES
import './Home.css';

//COMPONENTS
import Menu from '../../components/Menu/Menu';

export default class Home extends Component {
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
        <div id="home-page">
            <Menu currentPage={'home'}/>
            <div className="hero">
                <div className="text-box">
                    <p className="text">CASA.</p>            
                    <p className="text margin">[subst. fem.]</p>            
                    <p className="text">1.</p>            
                    <p className="text margin">lugar destinado a encontros, a reuniões ou à moradia de <br/>certas categorias de pessoas, cujos interesses, origens e <br/>cultura por vezes representa ou expressa.</p>            
                    <p className="text">2.</p>            
                    <p className="text margin">edifício de formatos e tamanhos variados, ger. de um ou <br/>dois andares, quase sempre destinado à habitação.</p>            
                    <p className="text">3.</p>            
                    <p className="text margin">grupo de pessoas vivendo sob o mesmo teto; família.</p>            
                </div>
            </div>
        </div>
        );
    }
}