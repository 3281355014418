//USEFUL MODULES
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router'

//STYLES
import './Menu.css';

class MenuComponent extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            menuOpen: false,
            first: true
        }
    }

    render() {
        let today = new Date();
        return (
            <div>
                {
                    isMobile ?
                        <div id="mobile-menu">
                            <div className="menu-box">
                                <img className="logo" src="/logo-horizontal.png" alt=""/>
                                <i className="fas fa-ellipsis-h icon" onClick={() => this.setState({ menuOpen: !this.state.menuOpen, first: false })}></i>
                            </div>
                            {
                                <CSSTransition
                                    in={this.state.menuOpen}
                                    classNames="menu-options"
                                >
                                    <div className={this.state.first ? "menu-slider invisible" : "menu-slider"}>
                                        <p className="menu-text" onClick={() => this.changePage("")}>HOME</p>
                                        <p className="menu-text" onClick={() => this.changePage("projetos")}>PROJETOS</p>
                                        <p className="menu-text" onClick={() => this.changePage("sobre")}>SOBRE</p>
                                        <p className="menu-text" onClick={() => this.changePage("contato")}>CONTATO</p>
                                    </div>
                                </CSSTransition>
                            }
                        </div>
                    : 
                    <div id="menu">
                        <div className="menu-wrapper">
                            <Link to="/" style={{textDecoration: 'none'}}>
                                <img className="logo" src="/logo.png" alt=""/>
                            </Link>
                            <Link to="/" style={{textDecoration: 'none'}}>
                                <p className={this.props.currentPage === "home" ? "title selected" : "title"}>Home</p>
                            </Link>
                            <Link to="/projetos" style={{textDecoration: 'none'}}>
                                <p className={this.props.currentPage === "projects" ? "title selected" : "title"}>Projetos</p>
                            </Link>
                            <Link to="/sobre" style={{textDecoration: 'none'}}>
                                <p className={this.props.currentPage === "about" ? "title selected" : "title"}>Sobre</p>
                            </Link>
                            <Link to="/contato" style={{textDecoration: 'none'}}>
                                <p className={this.props.currentPage === "contact" ? "title selected" : "title"}>Contato</p>
                            </Link>
                            <div className="icon-row">
                                <a href="https://www.facebook.com/sapearquitetos/" target="_blank" rel="noopener noreferrer">
                                    <i className="icon fab fa-facebook-square"></i>
                                </a>
                                <a href="https://www.instagram.com/sape.arquitetos/?hl=en" target="_blank" rel="noopener noreferrer">
                                    <i className="icon fab fa-instagram"></i>
                                </a>
                            </div>
                            <p className="email small-text">contato@sapearquitetos.com.br</p>
                            <p className="phone small-text">(11) 94348.3439</p>
                            <p className="phone small-text">(11) 99978.9213</p>
                            <div className="footer">
                                <p className="small-text">SAPÊ ARQUITETOS</p>
                                <p className="small-text">todos os direitos reservados {today.getFullYear()}</p>
                                <p className="small-text">site por <span>Gabriel Carechio</span></p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        
        );
    }

    changePage(page) {
        this.setState({ menuOpen: false }, () => {
            this.props.history.push("/"+page);
        });
    }
}

export default withRouter(MenuComponent);