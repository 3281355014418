//USEFUL MODULES
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

//STYLES
import './About.css';

//COMPONENTS
import Menu from '../../components/Menu/Menu';

export default class About extends Component {
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
        <div id="about-page">
            <Menu currentPage={'about'}/>
            <div className="page-layout">
                <div className="photo-column">
                    <img className="picture" src="/sobre_photo.png" alt=""/>
                    {isMobile ? <p className="description">Mariana e Vinícius</p> : null}
                </div>
                <div className="text-column">
                    <p className="title">A SAPÊ</p>
                    <p className="subtitle">NADA É TÃO SEU QUANTO ALGO PROJETADO PARA VOCÊ</p>

                    <p className="text margin"><span></span>Somos uma dupla de jovens arquitetos e urbanistas que acredita no gesto do abrigo. Seja em uma edificação ou em um abraço.</p>

                    <p className="text margin"><span></span>"Abrigar é acolher, confortar e proteger."</p>

                    <p className="text margin"><span></span>A arquitetura é um direito humano e deve ser pensada para o homem e seu ambiente. Tem caráter dignificador e absolutamente não somente estético.</p>
                    <p className="text"><span></span>Sapê é matéria prima do abrigo primitivo trançado, deixando claro que não se precisa de muito para se ter conforto. Sapê é também Sant’Anna e Pellegrino: parceria não somente na profissão, como na infância e na vida também.</p>
                    <p className="text margin"><span></span>Formados em Arquitetura e Urbanismo em 2015 pela Pontifícia Universidade Católica de Campinas, somos adeptos do conceito de simplicidade. Não acreditamos na máquina de viver. A esfera doméstica surge de uma delicada experiência de inúmeros pequenos rituais.  Nossa tarefa é projetar não somente belos espaços mas criar uma ambiência que será o pano de fundo para a vida, portanto, cada obra é única para cada pessoa respeitando suas respectivas peculiaridades.</p>

                    <p className="text margin"><span></span>Desde a graduação nos preocupamos com soluções que fugissem ao senso comum.  Uma acredita fortemente no caráter dignificador da arquitetura para a sociedade em sua totalidade e não somente para uma parcela seleta da mesma e faz mestrado na temática de projeto e seus impactos urbanos (grupo LADEUR: POSURB-ARQ/ PUCCampinas). O outro é especialista em História da Arte: Teoria e Crítica (2017) pelo Centro Universitário Belas Artes de São Paulo e se dedica à pesquisa das relações sociais e seus reflexos na forma urbana e no espaço público ao longo da história brasileira.</p>
                    <p className="text margin"><span></span>Prazer, Mariana e Vinícius! Vamos tomar um café?</p>
                </div>
            </div>
        </div>
        );
    }
}