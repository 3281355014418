//USEFUL MODULES
import React, { Component } from 'react';
import FloatingLabel, {
    floatingStyles,
    focusStyles,
    inputStyles,
    labelStyles
  } from 'floating-label-react'
import Modal from "react-modal";
import { isMobile } from 'react-device-detect';

//STYLES
import './Contact.css';

//COMPONENTS
import Menu from '../../components/Menu/Menu';

//PROVIDERS
import DataService from '../../providers/projects';

Modal.setAppElement("#root");

const inputStyle = isMobile ? {
    floating: {
      ...floatingStyles,
      color: '#8D825F',
      fontSize: "17px",
    },
    focus: {
      ...focusStyles,
      borderColor: '#8D825F'
    },
    input: {
      ...inputStyles,
      borderBottomWidth: 1,
      borderBottomColor: '#838382',
      width: '90%',
      fontSize: "18px",
      paddingBottom: "2px"
    },
    label: {
      ...labelStyles,
      marginTop: '.7em',
      color: '#838382',
      width: '100%',
      height: "40px"
    }
} : {
    floating: {
      ...floatingStyles,
      color: '#8D825F'
    },
    focus: {
      ...focusStyles,
      borderColor: '#8D825F'
    },
    input: {
      ...inputStyles,
      borderBottomWidth: 1,
      borderBottomColor: '#838382',
      width: '90%'
    },
    label: {
      ...labelStyles,
      marginTop: '.5em',
      color: '#838382',
      width: '100%'
    }
}

const customStyles = isMobile ? 
{
	content: {
        padding: "32px",
        margin: "150px auto",
        width: "70%",
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 9999999
    },
} : {
	content: {
        padding: "32px",
        margin: "150px auto",
        width: "480px",
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 9999999
    },
};

export default class Contact extends Component {
    
    dataService = new DataService();

    constructor(props){
        super(props);
        this.state={
            name: "",
            email: "",
            subject: "",
            message: "",
            alertIsOpen: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
        <div id="contact-page">
            <Menu currentPage={'contact'}/>
            <div className="page-layout">
                <p className="title">CONTATO</p>
                <FloatingLabel
                    id='name'
                    name='name'
                    placeholder='nome'
                    value={this.state.name}
                    onChange={(e)=>this.setState({name: e.target.value})}
                    styles={inputStyle}
                    type='text'/>
                <FloatingLabel
                    id='email'
                    name='email'
                    placeholder='e-mail'
                    onChange={(e)=>this.setState({email: e.target.value})}
                    styles={inputStyle}
                    type='email'/>
                <FloatingLabel
                    id='subject'
                    name='subject'
                    placeholder='assunto'
                    onChange={(e)=>this.setState({subject: e.target.value})}
                    styles={inputStyle}
                    type='text'/>
                <FloatingLabel
                    id='message'
                    element='textarea'
                    name='message'
                    placeholder='mensagem'
                    onChange={(e)=>this.setState({message: e.target.value})}
                    styles={inputStyle}
                    type='text'/>
                <button className="button" style={isMobile ? { float: "right", marginRight: "37px" } : null } onClick={()=>this.send()}>enviar</button>
                <p className="footer">Jundiaí - SP | contato@sapearquitetos.com.br | (11) 94348.3439 | (11) 99978.9213</p>
            </div>
            <Modal isOpen={this.state.alertIsOpen}
                onRequestClose={() => this.setState({alertIsOpen: false})}
                style={customStyles}
                className="alert-card-component"
                shouldCloseOnOverlayClick={false}>
                <p>Sucesso!</p>
                <p className="text">Obrigado pelo seu contato. Recebemos sua mensagem e entraremos em contato no e-mail {this.state.email} em breve.</p>
                <button className="button" onClick={()=>this.props.history.push("/")}>OK</button>
            </Modal>
        </div>
        );
    }

    send() {
        this.dataService.sendEmail(this.state.name, this.state.email, this.state.subject, this.state.message).then((res) => {
            console.log(res);
            this.setState({
                alertIsOpen: true,
                name: "",
                email: "",
                subject: "",
                message: ""
            }); 
        }).catch((err) => {
            console.log(err);
        });

    }
}