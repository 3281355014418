// USEFUL MODULES
import request from 'request';
import PROJECTS from '../data/projects';
import CATEGORIES from '../data/categories';

export default class DataService {

    getAllCategories() {
        return CATEGORIES;
    }

    get(category, id) {
        return PROJECTS[category].find((project)=> project.id === id);
    }

    getCategory(category) {
        return PROJECTS[category];
    }

    sendEmail(name, email, subject, message) {
        return new Promise((resolve, reject)=>{
            request.post({
                url: "https://sape-site-server.herokuapp.com/api/mail",
                headers: {
                    'content-type': 'application/json'
                },
                json: {
                    name: name,
                    email: email,
                    subject: subject,
                    message: message
                }
            }, (err, res, body) => {
                if (err || body.error) reject(body);
                resolve(body);
            });
        });
    }
}
