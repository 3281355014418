//USEFUL MODULES
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import ReactSwipeEvents from 'react-swipe-events';

//STYLES
import './ProjectDetail.css';

//COMPONENTS
import Menu from '../../../../components/Menu/Menu';

//PROVIDERS
import DataService from '../../../../providers/projects';

export default class ProjectDetail extends Component {
    
    constructor(props){
        super(props);
        this.state={
            mouseover: null,
            current: 0,
            margin: 0,
            offset: 0,
        }
        this.dataService = new DataService();
        this.project = this.dataService.get(
            this.props.match.params.category,
            Number(this.props.match.params.id)
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
        <div id="project-details-page">
            <Menu currentPage={'projects'}/>
            <div className="page-layout">
                <div className="parent-column">
                {
                    this.project ?
                        <div className="photo-column-fixed">
                        {
                            this.state.current > 0  && isMobile ? (
                                <div className="swipe-box mobile left" onClick={(e) => this.clicked(e, false)}>
                                    <i className="fas fa-chevron-left swipe"></i>
                                </div>) : null
                        }
                        {
                            !isMobile ? this.project.fotos.map((foto, index) => (
                                <img className="picture" src={foto} alt="" key={index}/>
                            ))
                            : <ReactSwipeEvents 
                                onSwiping={(e, originalX, originalY, currentX, currentY, deltaX, deltaY) => this.slide(e, originalX, originalY, currentX, currentY, deltaX, deltaY)} 
                                onSwiped={(e, originalX, originalY, endX, endY, deltaX, deltaY) => this.slided(e, originalX, originalY, endX, endY, deltaX, deltaY)}>
                                <div className="card-row" style={{ marginLeft: this.state.margin + "px" }}>
                                    {
                                        this.project.fotos.map((foto, index) => (
                                            <div className="picture-box" key={index}>
                                                <img className="picture" src={foto} alt=""/>
                                            </div>
                                        ))
                                    }
                                </div>
                            </ReactSwipeEvents>
                        }
                        {
                            this.state.current < (this.project.fotos.length - 1) && isMobile? (
                                <div className="swipe-box mobile right" onClick={(e) => this.clicked(e, true)}>
                                    <i className="fas fa-chevron-right swipe"></i>
                                </div>) : null
                        }
                        </div>
                        : null
                }
                {
                    this.project?
                        <div className="text-column">
                            <p className="title">{this.project.nome}</p>
                            <div>
                            {
                                Object.keys(this.project.attributes).map(key => (
                                    <div class="attribute-box">
                                        <img className="icon" src={`/icons/${key}.png`} alt=""/>
                                        <p className="attribute-text">{this.parseKey(key)}: {this.project.attributes[key]}</p>
                                    </div>
                                ))
                            }
                            </div>
                            {
                                this.project.texto.map((text, index) => (
                                    <p className="text margin" key={index}><span style={{display:"inline-block", width: "20px"}}></span>{text}</p>
                                ))
                            }
                            {
                                this.project.logos ?
                                    <div class="logos-wrapper">
                                    {
                                        this.project.logos.map((src, index) => (
                                            <img key={index} class="logo" src={src} alt=""/>
                                        ))
                                    }
                                    </div>
                                    : null
                            }
                        </div>
                        : null
                }
                </div>
            </div>
        </div>
        );
    }

    parseKey(key) {
        switch(key) {
            case 'type':
                return 'Tipo';
            case 'footage':
                return 'Metragem';
            case 'location':
                return 'Localização'
            case 'project':
                return 'Projeto'
            case 'status':
                return 'Status';
            default:
                return '';
        }
    }

    clicked(e, right) {
        if ((right && this.state.current + 1 >= this.project.fotos.length) || (!right && this.state.current - 1 < 0)) return;
        this.setState({
            margin: (right ? (this.state.current + 1) * -400 : (this.state.current - 1) * -400),
            offset: (right ? (this.state.current + 1) * -400 : (this.state.current - 1) * -400),
            current: (right ? this.state.current + 1 : this.state.current - 1)
        });
    }

    slide(e, originalX, originalY, currentX, currentY, deltaX, deltaY) {
        this.setState({
            margin: this.state.offset + deltaX
        });
    }

    slided(e, originalX, originalY, endX, endY, deltaX, deltaY) {
        if (this.state.margin > 0) {
            this.setState({
                margin: 0,
                current: 0
            });
        }
        else if (this.state.margin < (this.project.fotos.length - 1) * -400) {
            this.setState({
                margin: (this.project.fotos.length - 1) * -400,
                current: this.project.fotos.length - 1
            });
        }
        else {
            this.setState({
                offset: this.state.offset + deltaX,
                current: Math.floor(this.state.margin / -400)
            });
        }
    }
}