// USEFUL MODULES
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

// PAGES
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Projects from './pages/Projects/Projects';
import ProjectCategory from './pages/Projects/ProjectCategory/ProjectCategory';
import ProjectDetail from './pages/Projects/ProjectCategory/ProjectDetail/ProjectDetail';

ReactDOM.render(
  <div>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/sobre" component={About} />
          <Route path="/contato" component={Contact} />
          <Route path="/projetos" exact={true} component={Projects} />
          <Route path="/projetos/:category" exact={true} component={ProjectCategory} />
          <Route path="/projetos/:category/:id" exact={true} component={ProjectDetail} />
        </Switch>
      </BrowserRouter>
  </div>, 
  document.getElementById('root')
);
registerServiceWorker();