module.exports = {
    residencial: [
        {
            id: 1,
            nome: "Casa do Rio Acima",
            fotos: [
                "https://sapearquitetos.com.br/categorias/residencial/rio/00.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/rio/01.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/rio/02.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/rio/03.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/rio/04.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/rio/05.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/rio/06.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/rio/07.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/residencial/rio/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/residencial/rio/capa_pb.jpg",
            texto: [
                "A Casa do Rio Acima foi imaginada aos moldes das antigas casas de fazenda no Brasil, com grandes telhados à mostra e uma larga varanda que percorre toda a lateral da casa.",
                "Grandes portas e janelas ligam o interior à varanda, que passa a ser a principal ponto de encontro da casa.",
                "A paisagem da fazenda invade a sala de grande pé direito e passa a fazer parte do ambiente, como um grande quadro emoldurado pelas esquadrias.",
                "A área intima da casa foi pré-concebida para conter, além da suíte, dois quartos para os futuros filhos do casal.",
            ],
            attributes: {
                type: 'Habitacional',
                footage: '184m²',
                location: 'Jundiaí',
                project: 'Construção',
                status: 'Concluído'
            }
        },
        {
            id: 2,
            nome: "Apartamento Flex",
            fotos: [
                "https://sapearquitetos.com.br/categorias/residencial/vp/00.png",
                "https://sapearquitetos.com.br/categorias/residencial/vp/01.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/vp/02.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/vp/03.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/vp/04.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/vp/05.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/vp/06.jfif",
                "https://sapearquitetos.com.br/categorias/residencial/vp/07.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/vp/08.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/vp/09.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/vp/10.jfif",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/residencial/vp/capa.jfif",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/residencial/vp/capa_pb.jpg",
            texto: [
                "Buscando criar um espaço intimista para um jovem estudante de arte, o projeto se pautou pela simplicidade da paleta de cores e do design de marcenaria para garantir o destaque das obras de arte expostas nas paredes. O projeto simples do pequeno apartamento de 65m² valoriza os simples rituais do cotidiano, uma experiência estética do viver individual em escala reduzida onde o espaço social se une ao ateliê de trabalho.",
                "Projetar o pequeno apartamento foi como conceber um trabalho de curadoria. A preexistência eram as obras do artista e o projeto deveria respeitar a arte sem jamais se sobrepor a ela. A vida no apartamento será a performance do dia-a-dia. Viver é a questão que une arte e arquitetura."
            ],
            attributes: {
                type: 'Habitacional',
                footage: '65m²',
                location: 'Jundiaí',
                status: 'Concluído'
            }
        },
        {
            id: 3,
            nome: "Apartamento SELF",
            fotos: [
                "https://sapearquitetos.com.br/categorias/residencial/self/00.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/self/01.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/self/02.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/self/03.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/self/04.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/self/05.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/residencial/self/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/residencial/self/capa_pb.jpg",
            texto: [
                "O projeto do apartamento SELF foi idealizado para ser a primeira casa de um jovem casal.   Pensamos em cada detalhe para que o pequeno espaço de 58m² englobasse todas as funções necessárias para a vida a dois.",
                "Unimos o gosto de uma das meninas pela estética industrial ao ideal de sofisticação e praticidade da outra, mesclando texturas de cimento e serralherias ao uso de tons suaves e mármores brancos.",
                "Este projeto envolveu a realocação de pontos de infraestrutura elétrica e de gás, para permitir uma disposição não convencional dos eletrodomésticos, sendo que neste caso a área de cozinhar fica no centro do apartamento, unindo sempre as anfitriãs e seus convidados.",
            ],
            attributes: {
                type: 'Habitacional',
                footage: '58m²',
                location: 'Eloy Chaves - Jundiaí',
                project: 'Reforma',
                status: 'Iniciando obras'
            }
        },
        {
            id: 4,
            nome: "Apartamento Senador",
            fotos: [
                "https://sapearquitetos.com.br/categorias/residencial/senador/00.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/01.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/02.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/03.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/04.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/05.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/06.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/07.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/08.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/09.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/senador/10.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/residencial/senador/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/residencial/senador/capa_pb.jpg",
            texto: [
                "A premissa básica do projeto é o respeito à história. O apartamento de 150m² fica no residencial Senador, edifício construído no início da década de 1980 no centro da cidade de Jundiaí.",
                "Por se tratar de um apartamento antigo, buscamos restaurar elementos originais de sua arquitetura como o  piso de ipê e os azulejos pintados à mão no lavabo, porém ampliando visualmente a área social das salas através de demolições das alvenarias de vedação.",
                "Nosso projeto busca a renovação do apartamento e de sua essência modernista trazendo-o para a funcionalidade aplicada às novas lógicas do morar contemporâneo. Para tal foram refeitas desde as infraestruturas básicas de elétrica e hidráulica até os acabamentos e porcelanatos de modo que a nova decoração não se imponha como uma força a apagar a arquitetura original, mas que a complemente."
            ],
            attributes: {
                type: 'Habitacional',
                footage: '150m²',
                location: 'Jundiaí',
                project: 'Reforma',
                status: 'Concluído'
            }
        },
        {
            id: 5,
            nome: "Apartamento Tucuna",
            fotos: [
                "https://sapearquitetos.com.br/categorias/residencial/tucuna/00.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/tucuna/01.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/tucuna/02.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/tucuna/03.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/tucuna/04.jpg",
                "https://sapearquitetos.com.br/categorias/residencial/tucuna/05.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/residencial/tucuna/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/residencial/tucuna/capa_pb.jpg",
            texto: [
                "O apartamento Tucuna é um típico caso de reforma de atualização. A unidade habitacional não tinha nenhum problema pré-existente, cabendo ao projeto apenas a proposta de uma nova aparência, renovada com toques mais pessoais dos moradores.",
                "Buscamos a criação de pontos de interesse nas áreas sociais como grandes painéis de madeira e revestimentos geométricos, mantendo sempre uma unidade estética que passa por tons claros e texturas naturais, que garantem leveza e sofisticação.",
                "Para este projeto, foram utilizados serviços como: revisão de pontos elétricos, revestimentos, gessos, pintura, marcenaria planejada e serralheria."
            ],
            attributes: {
                type: 'Habitacional',
                footage: '185m²',
                location: 'Perdizes',
                project: 'Reforma',
                status: 'Iniciando obras'
            }
        },
    ],
    comercial: [
        {
            id: 1,
            nome: "Consultório da Irene",
            fotos: [
                "https://sapearquitetos.com.br/categorias/comercial/irene/00.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/irene/01.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/irene/02.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/irene/03.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/irene/04.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/irene/IMG_8572.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/irene/IMG_8582.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/comercial/irene/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/comercial/irene/capa_pb.jpg",
            texto: [
                "O pequeno consultório se encontra na edícula de uma casa comercial de salas de aluguel. O espaço possuía inúmeras interferências como paredes de azulejo antigas e bases de bancada em alvenaria, visto que o cômodo era a lavanderia da casa.",
                "Nosso projeto propôs intervenções simples e reversíveis criassem espaços decorativos como bancadas de madeira crua e prateleiras para objetos de decoração. O teto rosa e o painel de pinus que cobre os azulejos remetem ao conforto e ao estímulo positivo, afinal, a sala onde se tratam emoções deve acolher e convidar, sempre.",
            ],
            attributes: {
                type: 'Comercial',
                footage: '16m²',
                location: 'Jundiaí',
                project: 'Reforma',
                status: 'Concluído'
            }
        },
        {
            id: 2,
            nome: "Galpão de Coworkings",
            fotos: [
                "https://sapearquitetos.com.br/categorias/comercial/sesvesp/00.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/sesvesp/01.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/sesvesp/02.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/sesvesp/03.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/sesvesp/04.jpg",
                "https://sapearquitetos.com.br/categorias/comercial/sesvesp/05.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/comercial/sesvesp/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/comercial/sesvesp/capa_pb.jpg",
            texto: [
                "O projeto de salas corporativas foi realizado num grande galpão industrial de quatro andares na Barra Funda. O prédio funcionava como uma indústria têxtil e foi adaptada às pressas pelos locatários para uso comercial corporativo.",
                "Nossa missão foi setorizar os espaços, dividindo os andares por usos e criando salas de dimensões coerentes com os usos de seus sublocatários.",
                "Devido à grande área do projeto, conseguimos criar diversos espaços de descompressão e áreas técnicas e de eventos, como cozinhas e bares, além de ampliar o número de banheiros adaptados e inserir um elevador para garantir acessibilidade.",
                "Nosso projeto seguiu uma linha estética industrial sóbria e com paletas escuras, que refletem a visão de nossos clientes.",
            ],
            attributes: {
                type: 'Corporativo',
                footage: '1.600m²',
                location: 'Barra Funda - SP',
                project: 'Reforma',
                status: 'Aguardando início das obras'
            }
        },
    ],
    cenografia: [
        {
            id: 1,
            nome: "Experiência Lacta Intense",
            fotos: [
                "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/00.jpg",
                "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/01.jpg",
                "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/02.jpg",
                "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/03.jpg",
                "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/04.jpg",
                "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/05.jpg",
                "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/06.jpg",
                "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/07.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/cenografia/site-lacta/capa_pb.jpg",
            texto: [
                "Trabalhamos juntamente com o escritório de cenografia Estúdio Xingu para realizar ilustrações, desenhos técnicos e brainstorms criativos para eventos de divulgação de empresas e marcas.",
                "Para o Lançamento da linha Intense da Lacta, criamos um percurso de três salas onde o usuário experimenta estímulos como intensidade (som e cor), maciez e aromas.",
                "O projeto foi pensado para ocorrer durante um curto período de tempo num local controlado e depois desaparecer, portanto, foi um dos mais relevantes projetos efêmeros no qual nosso escritório teve participação.",
                "Responsáveis:",
                "Cenografia e criação: Estúdio Xingu",
                "Produção: VcArtwork",
                "Agência e divulgação: BFerraz",
                "Projeto técnico: SAPÊ Arquitetos.",
            ],
            logos: [
                "https://sapearquitetos.com.br/categorias/cenografia/parceiros/logo_estudio_xingu_menor.jpg",
                "https://sapearquitetos.com.br/categorias/cenografia/parceiros/VC_logo_B.jpg",
                "https://sapearquitetos.com.br/categorias/cenografia/parceiros/bferraz.JPG",
                "https://sapearquitetos.com.br/categorias/cenografia/parceiros/fresta.JPG",
            ],
            attributes: {
                type: 'Comercial (espaço efêmero)',
                footage: 'Variável',
                location: 'São Paulo',
                project: 'Cenografia',
                status: 'Concluído'
            }
        },
    ],
    exercicios: [
        {
            id: 1,
            nome: "Casa Modernista",
            fotos: [
                "https://sapearquitetos.com.br/categorias/exercicios/modernista/1.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/modernista/2.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/modernista/3.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/modernista/4.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/modernista/5.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/modernista/6.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/exercicios/modernista/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/exercicios/modernista/capa_pb.jpg",
            texto: [
                "Esta casa de 232m² surge como um ensaio estético do repertório moderno brasileiro das décadas de 50 e 60. O desafio foi criar uma residência com um programa completo e atual testando diferentes combinações de desníveis de modo a criar terraços, pátios, grutas e arrimos, onde os fragmentos programáticos pudessem ser inseridos.",
                "A casa se encontra num contexto imaginário, em meio às palmeiras e a selva densa, de modo a reforçar o simbolismo do movimento moderno brasileiro, tão compromissado com a invenção da civilização tropical.",
            ],
            attributes: {
                type: 'Habitacional',
                footage: '232m²',
                location: 'Inexistente',
                project: 'Exercício de projeto',
                status: 'Não construído'
            }
        },
        {
            id: 2,
            nome: "Chalés para turismo comunitário no Cumbe",
            fotos: [
                "https://sapearquitetos.com.br/categorias/exercicios/concurso/1.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/exercicios/concurso/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/exercicios/concurso/capa_pb.jpg",
            texto: [
                "Escala e Conhecimento foram palavras chave. O conceito de escala é vital, pois temos ciência das lógicas de vida no Cumbe e um projeto extensivo abalaria a coerência local. O conhecimento é igualmente crucial, pois o projeto não deve ser um estranho. O Cumbe, como espaço de resistência marcado por disputas identitárias e resiliente à pressões externas deve ser respeitado em seus saberes e qualquer intervenção que imponha normas ou cuja estética busque “modernizar” a região seria mais uma violência. População e visitantes devem ver nas intervenções ramificações das técnicas construtivas locais.",
                "A identidade local passa pelas técnicas construtivas regionais e seus materiais disponíveis.",
                "Assim sendo, nossa ideia se utiliza do barro, da palha e da madeira de carnaúba replicando técnicas ensinadas ao longo de gerações, porém revisando questões como funcionalidade e durabilidade. Tais questões justificam usos de técnicas exógenas como o tijolo de solo cimento ou o arco estrutural que atua também como coletor de águas pluviais.",
                "Analisando o programa, julgamos coerente que os chalés de ecoturismo fossem neutros em seu uso. Cremos que apesar da escala reduzida, nossas intervenções fomentarão pesquisas dos mais diversos ramos das ciências e cultura. As unidades devem ser conversíveis à maior gama de usos, do acolhimento de famílias a grupos de acadêmicos dispostos a conduzir suas pesquisas in loco.",
            ],
            attributes: {
            }
        },
        {
            id: 3,
            nome: "Apartamento Branco",
            fotos: [
                "https://sapearquitetos.com.br/categorias/exercicios/branco/1.png",
                "https://sapearquitetos.com.br/categorias/exercicios/branco/2.png",
                "https://sapearquitetos.com.br/categorias/exercicios/branco/3.png",
                "https://sapearquitetos.com.br/categorias/exercicios/branco/4.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/branco/5.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/branco/6.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/branco/7.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/branco/8.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/exercicios/branco/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/exercicios/branco/capa_pb.jpg",
            texto: [
                "O apartamento branco surge como uma experiência conceitual dos arquitetos. Um projeto de habitação segundo preceitos do minimalismo estético. A ambientação se constrói com o mínimo material e com a mínima interferência cromática a fim de criar um espaço neutro e cosmopolita como a metrópole na qual está inserido. Paradoxalmente à seriedade funcional do ambiente, o lúdico se faz presente no mobiliário multifuncional a exemplo do piso-sofá instalado na área social. Diversos paletes móveis fazem a função de assentos e no vazio dos elementos retirados criam-se espaços de uso variado. No vão além do mezanino há uma rede de uso livre.",
            ],
            attributes: {
                type: 'Habitacional',
                footage: '75m²',
                location: 'Inexistente',
                project: 'Interiores',
                status: 'Nunca construído'
            }
        },
        {
            id: 4,
            nome: "Apartamento J.V",
            fotos: [
                "https://sapearquitetos.com.br/categorias/exercicios/jv/1.png",
                "https://sapearquitetos.com.br/categorias/exercicios/jv/2.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/jv/3.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/jv/4.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/jv/5.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/jv/6.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/jv/7.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/jv/8.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/jv/9.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/exercicios/jv/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/exercicios/jv/capa_pb.jpg",
            texto: [
                "Localizado no andar térreo de um edifício de apartamentos compactos em Jundiaí, o conceito foi criar uma residência versátil para um jovem casal. A unidade estética se dá pela função clara dos espaços onde as esferas do habitar se unem. Não há divisão física entre o trabalhar e o receber amigos. Todos os espaços são conversíveis em diversos usos.",
                "A área útil de 60m² fornece espaço suficiente para o desempenho das múltiplas tarefas cotidianas do morar contemporâneo sem abrir mão do gosto particular dos moradores."
            ],
            attributes: {
                type: 'Habitacional',
                footage: '60m²',
                location: 'Inexistente',
                project: 'Exercício de projeto',
                status: 'Não construído'
            }
        },
        {
            id: 5,
            nome: "Casa Desdobramento Expandido",
            fotos: [
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/1.png",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/2.png",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/3.png",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/4.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/5.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/6.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/7.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/8.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/9.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/10.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/11.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/exercicios/desdobramento/capa_pb.jpg",
            texto: [
                "O universo se multiplica em progressão matemática. Do ponto, unidade mínima de existência, a forma se alonga em linha, com comprimento mas ainda sem matéria. As linhas, quando duplicadas delimitam planos que em sua extrusão se tornam volume. Do vazio entre volumes germina a essência básica da arquitetura, o espaço.",
                "A casa de 97,5m² é fruto dos desdobramentos espaciais do módulo de sua estrutura metálica. As linhas dos pilares delimitam os planos das lajes e entre piso e teto, o espaço do viver inunda a construção.",
                "A obra se encontra num terreno básico de 10x20m com desnível de 3 metros. Com o piso cortado em 2 plataformas, a casa surge flutuante do patamar mais alto e sob ela se desenvolve o espaço livre do quintal e da garagem. Optamos por evidenciar o caráter construtivo da unidade básica da arquitetura, o tijolo e na laje claraboias captam o sol da manhã para os quartos voltados ao corredor de vidro. A casa não oculta sua natureza, tudo é exposto e o desnudar da própria arquitetura constitui a beleza de sua austeridade.",
            ],
            attributes: {
                type: 'Habitacional',
                footage: '97,5m²',
                location: 'Inexistente',
                project: 'Exercício de projeto',
                status: 'Não construído'
            }
        },
        {
            id: 6,
            nome: "Vila Modular - Serra do Japi - Jundiaí",
            fotos: [
                "https://sapearquitetos.com.br/categorias/exercicios/chale/1.png",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/2.png",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/3.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/4.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/5.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/6.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/7.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/8.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/9.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/10.jpg",
                "https://sapearquitetos.com.br/categorias/exercicios/chale/11.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/exercicios/chale/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/exercicios/chale/capa_pb.jpg",
            texto: [
                "A Vila Modular é um experimento social que surge da vontade de reativar o elo desgastado entre homem e natureza. O processo civilizatório desconectou a vida humana dos ciclos naturais, atrelando o corpo social aos ritmos abstratos da urbanidade. Leis de proteção ambiental reafirmam a separação entre mundos natural e humano.",
                "O que foi criado para este projeto é uma releitura do morar no campo, unidades habitacionais autônomas que todavia se integram pela partilha do campo sem divisas. A construção levou em conta aspectos como baixo impacto ambiental, baixa quantidade de materiais e reduzidas complexidades técnicas. O objetivo foi criar arquiteturas rápidas e fáceis de construir, gerando a menor quantidade de resíduo na natureza que a circunda. Para tal a estrutura da casa passa a ser uma casca composta de módulos de concreto armado pré-moldados e a eles fixam-se as estruturas leves como mezaninos metálicos e esquadrias de vidro.",
                "A arquitetura da Vila é voltada à integração não apenas entre pessoa/natureza, mas entre pessoa/pessoa. As diversas casas se espalham pelo campo à beira do lago como núcleos familiares voltados à vida coletiva. As residências não se encerram em si, serviços como lavanderia e lazer são realizados coletivamente em edifícios comunais.",
            ],
            attributes: {
                type: 'Habitacional coletivo',
                footage: 'Variável',
                location: 'Serra do Japi',
                project: 'Exercício de projeto',
                status: 'Não construído'
            }
        },
    ],
    expografia: [
        {
            id: 1,
            nome: "Exposição Genealogia da Arquitetura",
            fotos: [
                "https://sapearquitetos.com.br/categorias/expografia/vinicius/1.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/vinicius/2.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/vinicius/3.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/vinicius/4.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/vinicius/5.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/expografia/vinicius/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/expografia/vinicius/capa_pb.jpg",
            texto: [
                "A exposição Genealogia da Arquitetura de Vinicius Pellegrino foi o primeiro projeto de curadoria e expografia realizado pelo nosso escritório e ocorreu na galeria Fernanda Perracini Milani em Jundiaí entre 22 de Agosto e 30 de Setembro de 2018.",
                "Concebemos a organização das obras no espaço levando em conta as relações entre arte exposta e o espaço construído da galeria. Concluímos que a expografia deveria ser neutra de modo a criar pontes entre a beleza da arquitetura da galeria e as arquiteturas representadas nas obras, para tal utilizamos elementos de materiais crus como a madeira das pranchetas e o metal dos ganchos e parafusos.",
                "Além do processo de organização do espaço, nosso escritório se encarregou da criação da linguagem gráfica da exposição e seu material de comunicação como banners, flyers e material de mídias digitais.",
            ],
            attributes: {
                type: 'Exposição de arte',
                footage: '80m²',
                location: 'Jundiaí',
                project: 'Expografia e curadoria',
                status: 'Concluído'
            }
        },
        {
            id: 2,
            nome: "Exposição Instrumentos de uso Livre",
            fotos: [
                "https://sapearquitetos.com.br/categorias/expografia/lucas/01.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/lucas/02.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/lucas/03.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/lucas/04.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/lucas/05.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/expografia/lucas/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/expografia/lucas/capa_pb.jpg",
            texto: [
                "Realizamos em agosto de 2018 o projeto de curadoria e expografia do artista Lucas Souza para sua exposição na Pinacoteca Municipal Diógenes Duarte Paes, em Jundiaí.",
                "A sala de exposição faz Parte de um edifício histórico tombado, logo, nossas intervenções deveriam respeitar a integridade e a historicidade do espaço. Optamos por uma estética o mais neutra possível, quase que como um cubo branco, minimalizando interferências do edifício neoclássico.",
                "Juntamente com o artista, que possuía diversas obras táteis, concluímos que o ideal seria criar uma atmosfera lúdica para a exposição, com objetos soltos no espaço e criando áreas circuláveis e de interação, o que por fim se mostrou um grande acerto visto que a exposição atraiu excursões de grupos escolares e de escoteiros!",
                "As demais obras foram expostas penduradas às paredes permitidas em molduras também desenvolvidas pelo escritório especialmente para esta exposição.",
            ],
            attributes: {
                type: 'Exposição de arte',
                footage: '20m²',
                location: 'Jundiaí',
                project: 'Expografia e curadoria',
                status: 'Concluído'
            }
        },
        {
            id: 3,
            nome: "Exposição Experimentações de espaço",
            fotos: [
                "https://sapearquitetos.com.br/categorias/expografia/vinicius_02/01.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/vinicius_02/02.jpg",
                "https://sapearquitetos.com.br/categorias/expografia/vinicius_02/03.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/expografia/vinicius_02/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/expografia/vinicius_02/capa_pb.jpg",
            texto: [
                "Ocorreu em 2018, na Pinacoteca Municipal Diógenes Duarte Paes em Jundiaí, a exposição Experimentações de espaço, do arquiteto e artista Vinicius Pellegrino.",
                "Por se tratar de uma mostra em um espaço histórico tombado, as intervenções deveriam ser de mínimo impacto e perfeitamente reversíveis.",
                "Optamos por uma expografia convencional com obras expostas nas paredes e em mesas de cavaletes, mantendo o ponto focal da exposição numa obra de grande valor arquitetônico e estético, reproduzindo seus padrões na parede ao fundo da galeria, o que permitiu uma experiência imersiva.",
                "O escritório também desenvolveu a comunicação visual da exposição, como cartazes, banners, convites e material para mídias online.",
            ],
            attributes: {
                type: 'Exposição de arte',
                footage: '20m²',
                location: 'Jundiaí',
                project: 'Expografia, curadoria e comunicação visual',
                status: 'Concluído'
            }
        },
    ],
    ilustracoes: [
        {
            id: 1,
            nome: "ILUSTRAÇÕES PARA MARCEL STEINER DESIGN DE INTERIORES",
            fotos: [
                "https://sapearquitetos.com.br/categorias/ilustracoes/marcel/01.jpg",
                "https://sapearquitetos.com.br/categorias/ilustracoes/marcel/02.jpg",
                "https://sapearquitetos.com.br/categorias/ilustracoes/marcel/03.jpg",
                "https://sapearquitetos.com.br/categorias/ilustracoes/marcel/04.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/ilustracoes/marcel/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/ilustracoes/marcel/capa_pb.jpg",
            texto: [
                "Ilustrações de concept art realizadas para diversos projetos do escritório de arquitetura e interiores Marcel Steiner.",
            ],
            logos: [
                "https://sapearquitetos.com.br/categorias/ilustracoes/marcel/logo%20marcel.jpg"
            ],
            attributes: {
            }
        },
        {
            id: 2,
            nome: "ILUSTRAÇÕES PARA EVENTO INTERATIVO",
            fotos: [
                "https://sapearquitetos.com.br/categorias/ilustracoes/ytb/01.jpg",
                "https://sapearquitetos.com.br/categorias/ilustracoes/ytb/02.jpg",
                "https://sapearquitetos.com.br/categorias/ilustracoes/ytb/03.jpg",
                "https://sapearquitetos.com.br/categorias/ilustracoes/ytb/04.jpg",
                "https://sapearquitetos.com.br/categorias/ilustracoes/ytb/05.jpg",
                "https://sapearquitetos.com.br/categorias/ilustracoes/ytb/06.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/ilustracoes/ytb/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/ilustracoes/ytb/capa_pb.jpg",
            texto: [
                "Ilustrações de concept art realizadas para um evento interativo em São Paulo.",
                "Neste caso, além das ilustrações, nosso escritório participou da concepção do projeto desde seu início e foi responsável pela elaboração dos projetos técnicos necessários.",
            ],
            attributes: {
            }
        },
        {
            id: 3,
            nome: "PROJETO NATURA",
            fotos: [
                "https://sapearquitetos.com.br/categorias/ilustracoes/natura/01.jpg",
                "https://sapearquitetos.com.br/categorias/ilustracoes/natura/02.jpg",
            ],
            imagem_colorida: "https://sapearquitetos.com.br/categorias/ilustracoes/natura/capa.jpg",
            imagem_preto_e_branco: "https://sapearquitetos.com.br/categorias/ilustracoes/natura/capa_pb.jpg",
            texto: [
                "Ilustrações de concept art realizadas para o espaço Natura no Rock In Rio edição de 2019.",
                "Aquarela em papel.",
            ],
            attributes: {
            }
        },
    ],
}