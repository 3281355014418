//USEFUL MODULES
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

//COMPONENTS
import Menu from '../../../components/Menu/Menu';
import GaleryCard from '../../../components/GaleryCard/GaleryCard';
import { Row } from 'react-simple-flex-grid';

//STYLES
import "react-simple-flex-grid/lib/main.css";

//PROVIDERS
import DataService from '../../../providers/projects';

export default class ProjectCategory extends Component {
    
    constructor(props){
        super(props);
        this.state={
            mouseover: null,
            active: -1
        }
        this.dataService = new DataService();
        this.projects = this.dataService.getCategory(this.props.match.params.category);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        // this.setState({ projects: dataService.getAll() });  
    }

    handleScroll(event) {
        this.setState({active: Math.floor((event.nativeEvent.target.scrollTop + 180)/300)});
    }

    isActive(id, index) {
        if(isMobile) {
            return this.state.active === index;
        } else {
            return this.state.mouseover === id;
        }
    }

    render() {
        return (
        <div id="projects-page">
            <Menu currentPage={'projects'}/>
            <div className="page-layout" onScroll={(e) => this.handleScroll(e)}>
                <Row gutter={0}>
                    {
                        this.projects.map((project, index) => (
                            <GaleryCard 
                                key={project.id}
                                index={index} 
                                project={project} 
                                active={this.isActive(project.id, index)}
                                onMouse={(value) => this.setState({mouseover: value})}
                                onClick={() => this.props.history.push(`/projetos/${this.props.match.params.category}/${project.id}`)}/>
                        ))
                    }
                </Row>
            </div>
        </div>
        );
    }
}